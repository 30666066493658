const productCategory = [
    { id : 1, label : "Makhana's", value : "Makhana's"},
    { id : 2, label : "Chips", value : "Chips"},
    { id : 4, label : " Combo’s", value : "Combo’s"},
    { id : 3, label : "Featured Products", value : "Featured Products"},
    { id : 4, label : "Best Seller ", value : "Best Seller"},
    { id : 5, label : "Gift Combos", value : "Gift Combos"},
    { id : 6, label : "New Arrivals", value : "New Arrivals"},
    
]


export default productCategory